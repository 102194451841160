import React, { useState, useRef, useEffect } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { Card } from 'primereact/card';
import { Button as PrimeButton } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { fetchLanguages } from '@utils/api/Lang';
import LoadingTable from '@components/Loading/LoadingTable';
import { formDataGenerator } from '@mbs-dev/react-helpers';

interface Language {
    id: number;
    name: string;
    code: string;
}

interface CategoryFormData {
    image: File | null;
    banner: File | null;
    categoryTranslates: {
        title: string;
        slug: string;
        language: string;
    }[];
}

const validationSchema = Yup.object({
    image: Yup.mixed().required('Category image is required'),
    banner: Yup.mixed().required('Banner image is required'),
    categoryTranslates: Yup.array().of(
        Yup.object({
            title: Yup.string().required('Title is required'),
            slug: Yup.string().required('Slug is required'),
            language: Yup.string().required('Language is required'),
        })
    ),
});

const CreateCategoryForm: React.FC = () => {
    const { data: languages, isLoading, error } = useQuery<Language[]>({
        queryKey: ['languages'],
        queryFn: fetchLanguages,
    });

    const toast = useRef<Toast | null>(null);

    const formik = useFormik<CategoryFormData>({
        initialValues: {
            image: null,
            banner: null,
            categoryTranslates: [{ title: '', slug: '', language: '' }],
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            formDataGenerator(values, formData);
            // if (values.image) {
            //     formData.append('image', values.image);
            // }
            // if (values.banner) {
            //     formData.append('banner', values.banner);
            // }
            
            values.categoryTranslates.forEach((translate, index) => {
                formData.append(`categoryTranslates[${index}][title]`, translate.title);
                formData.append(`categoryTranslates[${index}][slug]`, translate.slug);
                formData.append(`categoryTranslates[${index}][language]`, `api/languages/${translate.language}`);
            });

            console.log("formdata : " , values);
            
            // try {
            //     const response = await axios.post('/categories', formData, {
            //         headers: {
            //             'Content-Type': 'multipart/form-data',
            //         },
            //     });
            //     toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Category created successfully' });
            //     console.log("FormData sent:", response.data);
            // } catch (error) {
            //     toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to create category' });
            //     console.error('FormData error:', error);
            // }
        },
    });

    const handleFileSelect = (e: any, field: string) => {
        const file = e.files[0];
        formik.setFieldValue(field, file);
    };

    const itemTemplate = (file: any) => {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2) + ' MB';
        return (
            <div className="flex items-center flex-col gap-1 ">
                <img alt={file.name} role="presentation" src={file.objectURL} width={150} height={100} />
                <Tag value={fileSizeInMB} severity="warning" className="px-2 py-1" />
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

    const getSelectedLanguages = () => {
        return formik.values.categoryTranslates.map((translate) => translate.language);
    };

    const availableLanguages = (selectedLanguages: string[], currentLang: string) => {
        return languages?.filter(
            (lang) => !selectedLanguages.includes(lang.code) || lang.code === currentLang
        );
    };

    if (error) return <div>Error loading languages</div>;

    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Toast ref={toast} />
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Create Category</h2>
                    </div>

                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm">
                        <div className="flex flex-col gap-4 md:flex-row">
                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium">Category Image</label>
                                <FileUpload
                                    name="image"
                                    accept="image/*"
                                    customUpload
                                    auto
                                    onSelect={(e) => handleFileSelect(e, 'image')}
                                    itemTemplate={itemTemplate}
                                    chooseOptions={chooseOptions}
                                />
                                {formik.errors.image && formik.touched.image && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.image}</p>
                                )}
                            </div>

                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium">Banner Image</label>
                                <FileUpload
                                    name="banner"
                                    accept="image/*"
                                    customUpload
                                    auto
                                    onSelect={(e) => handleFileSelect(e, 'banner')}
                                    itemTemplate={itemTemplate}
                                    chooseOptions={chooseOptions}
                                />
                                {formik.errors.banner && formik.touched.banner && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.banner}</p>
                                )}
                            </div>
                        </div>

                        {isLoading ? (
                            <LoadingTable />
                        ) : (
                            <FieldArray name="categoryTranslates">
                                {({ remove, push }) => (
                                    <div className="space-y-4">
                                        {formik.values.categoryTranslates.map((translate, index) => {
                                            const selectedLanguages = getSelectedLanguages();
                                            const filteredLanguages = availableLanguages(selectedLanguages, translate.language);

                                            const errors = formik.errors.categoryTranslates?.[index];
                                            const touched = formik.touched.categoryTranslates?.[index];

                                            return (
                                                <div key={index} className="flex flex-col md:flex-row items-center gap-4 border-2 border-green-100 p-2 rounded-md shadow-md">
                                                    <input
                                                        type="text"
                                                        name={`categoryTranslates[${index}][title]`}
                                                        value={translate.title}
                                                        placeholder="Title"
                                                        onChange={formik.handleChange}
                                                        className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                                                    />
                                                    {touched?.title && typeof errors === 'object' && errors?.title && (
                                                        <p className="text-red-500 text-xs mt-1">{errors.title}</p>
                                                    )}

                                                    <input
                                                        type="text"
                                                        name={`categoryTranslates[${index}][slug]`}
                                                        value={translate.slug}
                                                        placeholder="Slug"
                                                        onChange={formik.handleChange}
                                                        className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                                                    />
                                                    {touched?.slug && typeof errors === 'object' && errors?.slug && (
                                                        <p className="text-red-500 text-xs mt-1">{errors.slug}</p>
                                                    )}

                                                    <select
                                                        name={`categoryTranslates[${index}][language]`}
                                                        value={translate.language}
                                                        onChange={formik.handleChange}
                                                        className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                                                    >
                                                        <option value="">Select Language</option>
                                                        {filteredLanguages?.map((lang) => (
                                                            <option key={lang.id} value={lang.code}>
                                                                {lang.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {touched?.language && typeof errors === 'object' && errors?.language && (
                                                        <p className="text-red-500 text-xs mt-1">{errors.language}</p>
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={() => remove(index)}
                                                        className="p-2 bg-pink-500 text-white rounded-md shadow-sm"
                                                    >
                                                        &#10006;
                                                    </button>
                                                </div>
                                            );
                                        })}
                                        <div className="flex justify-start">
                                            <PrimeButton
                                                label="Add Translation"
                                                icon="pi pi-plus"
                                                size="small"
                                                onClick={() => push({ title: '', slug: '', language: '' })}
                                                className="bg-green-100 text-green-700 text-xs"
                                            />
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        )}

                        <div className="flex justify-center">
                            <PrimeButton label="Create Category" icon="pi pi-check" className="p-button-success text-xs" />
                        </div>
                    </form>
                </Card>
            </div>
        </FormikProvider>
    );
};

export default CreateCategoryForm;
