import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '@pages/Login';
import Home from '@pages/Home';
import { ProtectedRoute } from '@routes/ProtectedRoute';
import { HomeRoute } from '@routes/HomeRoute';
import Profile from '@pages/Profile';
import CategoryList from '@pages/Categories/CategoriesList';
import NotAuthorized from '@pages/NotAuthorized';
import CategoriesAdd from '@pages/Categories/CategoriesAdd';


function MainNavigation() {


  return (

    <Routes>
      
      <Route path='/not-authorized' element={<NotAuthorized />} />

      <Route element={<HomeRoute />}>
        <Route path='/' element={<Login />} />
      </Route>
      <Route path='/dashboard' element={<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', "ROLE_USER"]} />}>
        <Route path='home' element={<Home />} />
        <Route path='profile' element={<Profile />} />
        <Route path='categories' element={<CategoryList />} />
        <Route path='categories/add' element={<CategoriesAdd />} />
      </Route>


      <Route path='/dashboard/commercial' element={<ProtectedRoute roles={['ROLE_COMMERCIAL']} />}>
        <Route path='home' element={<Home />} />
      </Route>

      <Route path='/dashboard/livreur' element={<ProtectedRoute roles={['ROLE_LIVREUR']} />}>
        <Route path='home' element={<Home />} />
      </Route>
    </Routes>
  );
}

export default MainNavigation;
