// src/components/SubcategoryModal.tsx
import React from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface SubcategoryModalProps {
    visible: boolean;
    onHide: () => void;
    subCategories: any[];
}

const SubcategoryModal: React.FC<SubcategoryModalProps> = ({ visible, onHide, subCategories }) => {
    return (
        <Dialog
            header="Subcategories"
            visible={visible}
            onHide={onHide}
            style={{ width: "50vw" }}
            breakpoints={{ '960px': '75vw', '640px': '90vw' }} // Responsive design
            className="p-dialog-custom"
            dismissableMask // Close on outside click
        >
            {subCategories.length > 0 ? (
                <ul className="space-y-3">
                    {subCategories.map((sub) => (
                        <li
                            key={sub.id}
                            className="border-b border-gray-200 pb-2 flex justify-between items-center"
                        >
                            <span className="text-gray-700 text-lg">Subcategory ID: {sub.id}</span>
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="flex justify-center items-center h-24">
                    <p className="text-gray-500 text-lg font-medium">No Subcategories</p>
                </div>
            )}
        </Dialog>
    );
};

export default SubcategoryModal;
