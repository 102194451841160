// src/pages/CategoriesList.tsx
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom"; // For navigation to add new category
import { fetchCategories } from "@utils/api/category";
import { columns } from "@components/categories/columns";
import { Card } from "primereact/card"; // PrimeReact Card
import { Button } from "primereact/button"; // PrimeReact Button
import { Panel } from "primereact/panel";
import Loading from "@components/Loading/Loading";
import LoadingTable from "@components/Loading/LoadingTable";

const CategoriesList: React.FC = () => {
    const { data, isLoading, error } = useQuery({
        queryKey: ["categories"],
        queryFn: fetchCategories,
    });

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(), // To enable sorting
    });

    const navigate = useNavigate(); // To navigate to the "Add Category" page

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
            <h2 className="text-lg font-semibold text-green-700">Categories List</h2>
            <Button
                label="Add Category"
                icon="pi pi-plus"
                className="p-button-sm p-button-outlined"
                onClick={() => navigate("/dashboard/categories/add")}
            />
        </div>
    );

    if (error) return <div className="text-center mt-5 text-md text-red-500">Error loading categories</div>;

    return (
        <div className="pt-3">
            <Panel headerTemplate={header} className="rounded-lg  p-2">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm ">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="bg-green-400 text-white uppercase text-xs text-center leading-tight">
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getIsSorted() ? (header.column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody >
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id} className="border-b hover:bg-green-50 transition ease-in-out text-sm">
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
};

export default CategoriesList;
