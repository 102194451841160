import { useState } from "react";
import SubcategoryModal from "../Modals/SubcategoryModal";

// Subcategory cell component to display count and trigger modal
export const SubcategoryCell: React.FC<{ subCategory: any[] }> = ({ subCategory }) => {
    const [visible, setVisible] = useState(false); // For controlling modal visibility

    const openModal = () => {
        if (subCategory.length > 0) setVisible(true);
    };

    return (
        <>
            <span
                className={`cursor-pointer text-blue-500 underline ${subCategory.length === 0 ? "text-gray-500" : ""}`}
                onClick={openModal}
            >
                {subCategory.length > 0 ? `${subCategory.length} Subcategories` : "No Subcategories"}
            </span>

            {/* Use the SubcategoryModal */}
            <SubcategoryModal
                visible={visible}
                onHide={() => setVisible(false)}
                subCategories={subCategory}
            />
        </>
    );
};
