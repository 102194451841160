// src/components/Columns.tsx
import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import { bannerImgUrl, categoryImgUrl } from "@helpers/Helpers";
import SubcategoryModal from "./Modals/SubcategoryModal";
import { SubcategoryCell } from "./cells/SubcategoryCell";
import { Button } from "primereact/button";

let imageNotFound = require("@resources/imageNotFound.png");

export const columns: ColumnDef<any>[] = [
    {
        header: "ID",
        accessorKey: "id",
        cell: ({ row }) => <span className="text-sm">{row.original.id}</span>,
    },
    {
        header: "Image",
        accessorKey: "image",
        cell: ({ row }) =>
            row.original.image ? (
                <Image src={`${categoryImgUrl}/${row.original.image}`} alt="Category" width="100" preview downloadable={true} />
            ) : (
                <Image src={imageNotFound} alt="Image Not Found" width="100" />
            ),
    },
    {
        header: "Banner",
        accessorKey: "banner",
        cell: ({ row }) =>
            row.original.banner ? (
                <Image src={`${bannerImgUrl}/${row.original.banner}`} alt="Banner" width="100" preview downloadable={true}/>
            ) : (
                <span className="text-center">---</span>
            ),
    },
    {
        header: "Translations",
        accessorKey: "categoryTranslates",
        cell: ({ row }) =>
            row.original.categoryTranslates.length > 0 ? (
                <div className="flex flex-col gap-1">
                    {row.original.categoryTranslates.map((translation: any) => (
                        <Tag key={translation.id} value={`${translation.title} (${translation.language.code})`} className="text-xs" severity="info" />
                    ))}
                </div>
            ) : (
                <Tag value="No Translations" severity="warning"  className="text-xs" />
            ),
    },
    {
        header: "Subcategories",
        accessorKey: "subCategory",
        cell: ({ row }) => <SubcategoryCell subCategory={row.original.subCategory} />,
    },
    {
        header: "Parent",
        accessorKey: "parent",
        cell: ({ row }) =>
            row.original.parent ? (
                <div className="flex flex-col justify-center gap-1">
                    <Tag value={`Parent ID: ${row.original.parent.id}`} severity="info" className="text-xs" />
                    {row.original.parent.image ? (
                        <Image src={`${categoryImgUrl}/${row.original.parent.image}`} alt="Parent" width="80" preview />
                    ) : (
                        <Image src={imageNotFound} alt="Parent Not Found" width="40" />
                    )}
                </div>
            ) : (
                <Tag value="No Parent" severity="danger" className="text-xs" />
            ),
    },
    {
        header: "Actions",
        accessorKey: "actions",
        cell: ({ row }) => (
            <div className="flex space-x-1">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info p-button-sm"
                    tooltip="Edit"
                    tooltipOptions={{ position: "top" }}
                // onClick={() => handleEdit(row.original.id)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-sm"
                    tooltip="Delete"
                    tooltipOptions={{ position: "top" }}
                // onClick={() => handleDelete(row.original.id)}
                />
            </div>
        ),
    },
];
