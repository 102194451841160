import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import 'primereact/resources/primereact.min.css';         // Core CSS
import 'primeicons/primeicons.css';                       // Prime Icons
import { useAuth } from '@context/AuthContext';
import Header from '@components/Home/Header';
import ProfileHeader from '@components/profile/ProfileHeader';

type AppSidebarPros = {
    visible: boolean,
    setVisible: any
}
const AppSidebar: React.FC<AppSidebarPros> = ({ visible, setVisible }) => {

    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleNavigation = (path: string) => {
        navigate(path);
        setVisible(false);
    };


    const menuItems = [
        {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            command: () => handleNavigation('/dashboard'),
        },
        {
            label: 'Users',
            icon: 'pi pi-fw pi-users',
            items: [
                {
                    label: 'Add User',
                    icon: 'pi pi-fw pi-user-plus',
                    command: () => handleNavigation('/users/add'),
                },
                {
                    label: 'List Users',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/users/list'),
                },
            ],
        },
        {
            label: 'Products',
            icon: 'pi pi-fw pi-box',
            items: [
                {
                    label: 'Add Product',
                    icon: 'pi pi-fw pi-plus',
                    command: () => handleNavigation('/products/add'),
                },
                {
                    label: 'List Products',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/products/list'),
                },
            ],
        },
        {
            label: 'Categories',
            icon: 'pi pi-fw pi-th-large',
            items: [

                {
                    label: 'List Categories',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/categories'),
                },
                {
                    label: 'Add Category',
                    icon: 'pi pi-fw pi-plus',
                    command: () => handleNavigation('/dashboard/categories/add'),
                }
            ],
        },
        {
            label: 'Settings',
            icon: 'pi pi-fw pi-cog',
            command: () => handleNavigation('/settings'),
        },
        {
            label: 'Logout',
            icon: 'pi pi-fw pi-sign-out',
            command: () => {
                logout()
            },
        },
    ];

    let logo = require('@resources/logo.png');
    return (
        <div className="sidebar-container">
            {/* <Button label='Menu' iconPos='right' icon="pi pi-arrow-right" onClick={() => setVisible(true)} className="absolute top-5 left-5 border-spacing-2 border-whitel" /> */}
            <Sidebar visible={visible} onHide={() => setVisible(false)}>

                <div>
                    <Link to="/dashboard/home" className="flex items-center justify-center mb-3">
                        <img alt="logo" src={logo} className="h-8 md:h-12 w-auto" />
                    </Link>
                </div>

                
                <PanelMenu model={menuItems} className="w-full" />
            </Sidebar>
        </div>
    );
};

export default AppSidebar;
